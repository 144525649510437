import styled from '@emotion/styled'

import imgMobile from '../../../assets/images/landing/qasa-card/welcome_home_mobile.jpg'
import imgDesktop from '../../../assets/images/landing/qasa-card/welcome_home.jpg'

import { ResponsiveImage } from './responsive-image'

const Img = styled(ResponsiveImage)({
  height: '80vh',
  minHeight: 500,
  objectFit: 'cover',
})
export function WelcomeHomeCard() {
  return (
    <Img
      src={imgMobile}
      alt=""
      srcSet={{
        690: imgMobile,
        1920: imgDesktop,
      }}
      sizes="100vw"
    />
  )
}
