import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {
  getUnselectedAreaOptions,
  type AreaSearchProps,
  selectedAreaToLabel,
  selectedAreaToSublabel,
  useAreaSuggestions,
} from '@qasa/app/src/modules/area-search'
import { useBreakpointValue } from '@qasa/qds-ui'

import { AutocompleteLanding } from './autocomplete'

export function AreaSearchAutocompleteLanding({ value, onChange }: AreaSearchProps) {
  const { t } = useTranslation('area_search')
  const suggestionsLimit = useBreakpointValue({ base: 12, md: 8 }, { ssr: true })
  const [searchQuery, setSearchQuery] = useState('')
  const { isLoadingSuggestions, suggestedAreas } = useAreaSuggestions({
    searchQuery,
    suggestionsLimit,
  })

  const nonSelectedAreas = getUnselectedAreaOptions({ areaSuggestions: suggestedAreas, selectedAreas: value })
  const placeholder =
    value.length >= 3
      ? t('input_placeholder.max_selected')
      : value.length === 0
        ? t('input_placeholder.default')
        : t('input_placeholder.add_another_area')

  return (
    <AutocompleteLanding
      inputValue={searchQuery}
      isLabelVisuallyHidden
      label={t('label')}
      options={nonSelectedAreas}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      optionToLabel={selectedAreaToLabel}
      optionToSublabel={selectedAreaToSublabel}
      onInputValueChange={setSearchQuery}
      isLoading={isLoadingSuggestions}
    />
  )
}
