import type { UIEvent } from 'react'
import { useEffect, useState } from 'react'

type InfiniteHorizontalScrollParams = {
  contentWidth: number
}
export function useInfiniteHorizontalScroll({ contentWidth }: InfiniteHorizontalScrollParams) {
  const [isScrollDisabled, setIsScrollDisabled] = useState(false)

  const setScroll = (element: HTMLDivElement, position: number) => {
    element.scrollLeft = position
  }
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget
    const scrollWidth = container.scrollWidth
    const currentScrollPosition = container.scrollLeft

    if (!isScrollDisabled) {
      const isCloseToRightEnd =
        currentScrollPosition + Math.max(container.clientWidth, contentWidth) >= scrollWidth
      const isCloseToLeftEnd = currentScrollPosition <= 0

      if (isCloseToRightEnd) {
        setScroll(container, currentScrollPosition - contentWidth)
        setIsScrollDisabled(true)
      } else if (isCloseToLeftEnd) {
        setScroll(container, contentWidth)
        setIsScrollDisabled(true)
      }
    }
  }

  useEffect(() => {
    let timer: null | number = null
    if (isScrollDisabled) {
      timer = window.setTimeout(() => {
        setIsScrollDisabled(false)
      }, 40)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  })

  return { handleScroll }
}
