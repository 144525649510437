import styled from '@emotion/styled'
import { Stack, Paragraph, useBreakpointValue } from '@qasa/qds-ui'
import type { ReactNode } from 'react'

const Wrapper = styled.div({
  position: 'relative',
})

const Image = styled.img({
  width: '100%',
  height: '80vh',
  minHeight: 480,
  objectFit: 'cover',
})

const OverlayContent = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  padding: `${theme.spacing['8x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const StyledParagraph = styled(Paragraph)(({ theme }) => ({
  color: theme.colors.core.white,
}))

type BackgroundImageProps = {
  img: string
  label: string
  children: ReactNode
}

export function BackgroundImage({ children, img, label }: BackgroundImageProps) {
  const isMdOrAbove = useBreakpointValue({ base: false, md: true }, { ssr: true })

  return (
    <Wrapper>
      <Image src={img} alt={''} />
      <OverlayContent justifyContent={'space-between'}>
        <StyledParagraph>{label}</StyledParagraph>
        <Stack
          direction={'row'}
          wrap={isMdOrAbove ? 'nowrap' : 'wrap'}
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          gap={'12x'}
        >
          {children}
        </Stack>
      </OverlayContent>
    </Wrapper>
  )
}
