import type { StaticImageData } from 'next/image'
import type { ComponentPropsWithoutRef } from 'react'

type ResponsiveStaticImageProps = Omit<ComponentPropsWithoutRef<'img'>, 'src' | 'srcSet'> & {
  src: string | StaticImageData
  srcSet: Record<number, string | StaticImageData>
  alt: string
  sizes?: string
  fetchPriority?: 'auto' | 'high' | 'low'
}

export function ResponsiveImage({
  src: srcProp,
  alt,
  srcSet: srcSetProp,
  sizes,
  fetchPriority,
  ...rest
}: ResponsiveStaticImageProps) {
  // If it's a static image import, we need to extract the src from the object.
  const src = typeof srcProp === 'string' ? srcProp : srcProp.src

  /**
   * Convert the srcSet object into a string of comma-separated values.
   */
  const srcSet = Object.entries(srcSetProp)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map(([key, value]) => {
      const src = typeof value === 'string' ? value : value.src
      return `${src} ${key}w`
    })
    .join(', ')

  return (
    <img
      alt={alt}
      src={src}
      srcSet={srcSet}
      decoding="async"
      loading="lazy"
      sizes={sizes ?? '100vw'}
      fetchPriority={fetchPriority}
      {...rest}
    />
  )
}
