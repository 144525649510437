import styled from '@emotion/styled'
import type { AnimationPlaybackControls } from 'framer-motion'
import { useAnimate } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useBreakpointValue } from '@qasa/qds-ui'

import { CARD_WIDTH_DESKTOP, PlacesCard } from './places-card'
import { usePlaces } from './use-places'
import { useInfiniteHorizontalScroll } from './use-infinite-horizontal-scroll'

const CARD_WIDTH_WITH_PADDING = CARD_WIDTH_DESKTOP + 48

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.brandTertiary,
  paddingTop: theme.spacing['12x'],
  paddingBottom: theme.spacing['12x'],
}))

const ScrollingContainer = styled.div({
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
})

const ItemsList = styled.ul({
  display: 'flex',
  justifyContent: 'space-between',
})
const PlaceListItem = 'li'

export function Places() {
  const isMobile = useBreakpointValue({ base: true, sm: false }, { ssr: true })
  const places = usePlaces()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [animationControls, setAnimationControls] = useState<AnimationPlaybackControls | undefined>(undefined)
  // NOTE: used to fill the space after elements before the animation is reset (to make it look like a loop)
  const [doubleElements, setDoubleElements] = useState<number>(1)
  const [scope, animate] = useAnimate()
  const { handleScroll } = useInfiniteHorizontalScroll({
    contentWidth: CARD_WIDTH_WITH_PADDING * places.length,
  })

  const contentWidth = CARD_WIDTH_WITH_PADDING * places.length

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([target]) => {
        if (target.isIntersecting) {
          animationControls?.play()
        } else {
          animationControls?.pause()
        }
      },
      {
        threshold: 0.8,
      },
    )
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [animationControls])

  useEffect(() => {
    setDoubleElements(Math.max(Math.ceil((2 * window.innerWidth) / contentWidth), 1))
  }, [contentWidth])

  useEffect(() => {
    if (!isMobile) {
      const controls = scope.current
        ? animate(
            scope.current,
            { x: -contentWidth },
            {
              repeat: Infinity,
              repeatType: 'loop',
              duration: places.length * 4,
              ease: 'linear',
            },
          )
        : undefined
      setAnimationControls(controls)
    }
  }, [animate, contentWidth, isMobile, scope, places.length])

  useEffect(() => {
    let timer: null | number = null
    if (isHovered) {
      timer = window.setTimeout(() => {
        animationControls?.pause()
      }, 300)
    } else {
      animationControls?.play()
    }
    // NOTE: clear timeout if the user exits the places section before the pause function ran
    return () => {
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [animationControls, isHovered])

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const placesToRender = places.map((place) => {
    return (
      <PlaceListItem key={place.name}>
        <PlacesCard place={place} />
      </PlaceListItem>
    )
  })

  return (
    <Wrapper ref={wrapperRef}>
      {isMobile ? (
        <ScrollingContainer>
          <ItemsList key="mobile">{placesToRender}</ItemsList>
        </ScrollingContainer>
      ) : (
        <ScrollingContainer onScroll={handleScroll}>
          <ItemsList key="desktop" ref={scope} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {placesToRender}
            {[...Array(doubleElements)].map(() =>
              places.map((place) => (
                <PlaceListItem key={place.name}>
                  <PlacesCard place={place} />
                </PlaceListItem>
              )),
            )}
          </ItemsList>
        </ScrollingContainer>
      )}
    </Wrapper>
  )
}
