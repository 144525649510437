import styled from '@emotion/styled'
import { ArrowDownIcon, IconButton } from '@qasa/qds-ui'

const Arrow = styled(IconButton)<{
  $isOpen: boolean
}>(({ $isOpen }) => ({
  transform: $isOpen ? 'rotate(-180deg)' : 'initial',
  transition: 'transform 0.3s ease-in-out',
  marginLeft: 8,
}))

type RotatingArrowProps = { onClick?: () => void; isOpen: boolean; className?: string }

export function RotatingArrow({ onClick, isOpen, className }: RotatingArrowProps) {
  return (
    <Arrow
      onClick={onClick ? onClick : () => null}
      $isOpen={isOpen}
      icon={ArrowDownIcon}
      label="arrow"
      {...{ className }}
    />
  )
}
