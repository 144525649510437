import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

const TabButton = styled.button<{ $isSelected: boolean }>(({ theme, $isSelected }) => ({
  paddingBottom: 16,
  height: '100%',
  fontWeight: theme.typography.title.md.fontWeight,
  ...($isSelected && {
    borderBottom: `3px solid ${theme.colors.bg.brandSecondary}`,
    color: theme.colors.bg.brandSecondary,
  }),
}))

export enum SearchTabEnum {
  LONGTERM = 'find-home',
  VACATION = 'find-vacation',
}
type SearchBoxTabsProps = {
  selectedTab: SearchTabEnum
  setSelectedTab: (newTab: SearchTabEnum) => void
}
export function SearchBoxTabs({ selectedTab, setSelectedTab }: SearchBoxTabsProps) {
  const { t } = useTranslation('landing_top_section')
  return (
    <Stack gap="6x" direction="row">
      <TabButton
        $isSelected={selectedTab === SearchTabEnum.LONGTERM}
        onClick={() => setSelectedTab(SearchTabEnum.LONGTERM)}
      >
        {t('longterm_tab')}
      </TabButton>
      <TabButton
        $isSelected={selectedTab === SearchTabEnum.VACATION}
        onClick={() => setSelectedTab(SearchTabEnum.VACATION)}
      >
        {t('vacation_tab')}
      </TabButton>
    </Stack>
  )
}
