import { Button, Spacer, Label, Stack } from '@qasa/qds-ui'
import { Fragment, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { SearchHistoryDropdown } from '@qasa/app'
import {
  AutocompleteInput,
  AutocompleteListbox,
  useAutocomplete,
} from '@qasa/app/src/components/autocomplete/web'

import { VisuallyHidden } from '../../../../ui-shared/_core/visually-hidden'

const AutocompleteWrapper = styled.div({
  position: 'relative',
})

const ButtonElementWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  right: theme.spacing['5x'],
  marginRight: `-${theme.spacing['5x']}`,
  paddingTop: theme.spacing['2x'],
  paddingRight: 0,
  paddingBottom: theme.spacing['2x'],
  paddingLeft: theme.spacing['2x'],
  backgroundColor: theme.colors.bg.default,
  borderRadius: theme.radii.full,
  height: '100%',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  height: '100%',
  fontSize: theme.typography.body.lg.fontSize,
  paddingLeft: theme.spacing['8x'],
  paddingRight: theme.spacing['8x'],
  minWidth: 'auto',
}))

const AutocompleteBox = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  width: '100%',
  height: 80,
  border: '1px solid',
  borderColor: theme.colors.border.default,
  borderRadius: theme.radii.full,
  paddingLeft: theme.spacing['2x'],
  paddingRight: theme.spacing['2x'],
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
}))

const InputWrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  overflowX: 'scroll',
  overflowY: 'hidden',
  paddingRight: theme.spacing['5x'],
  height: '100%',
  fontSize: theme.typography.body.lg.fontSize,
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

type OptionToLabel<TOption> = (option: TOption | null) => string
type OptionToSublabel<TOption> = (option: TOption | null) => string | undefined | null

type AutocompleteProps<TOption> = {
  value: TOption[]
  options: TOption[]
  onChange: (nextValue: TOption[]) => void
  inputValue: string
  onInputValueChange: (inputValue: string) => void
  optionToLabel: OptionToLabel<TOption>
  optionToSublabel?: OptionToSublabel<TOption>
  placeholder?: string
  label: string
  isLabelVisuallyHidden?: boolean
  maxOptions?: number
  isLoading?: boolean
  errorMessage?: string
}
export function AutocompleteLanding<TOption>({
  options,
  value,
  placeholder,
  optionToLabel,
  optionToSublabel,
  onChange,
  maxOptions = 3,
  inputValue,
  onInputValueChange,
  isLoading = false,
}: AutocompleteProps<TOption>) {
  const {
    isOpen: isAutocompleteOpen,
    selectedOptions,
    highlightedIndex,
    getComboboxProps,
    getSelectedItemProps,
    removeSelectedItem,
    getInputProps,
    getDropdownProps,
    getMenuProps,
    getItemProps,
  } = useAutocomplete<TOption>({
    options,
    value,
    onChange,
    inputValue,
    onInputValueChange,
    optionToLabel,
  })
  const { t } = useTranslation('hero')

  const hasSelectedOptions = Boolean(selectedOptions.length)

  const timeoutRef = useRef<number | null>(null)

  const handleInputFocus = () => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current)
    }
    if (!inputValue) {
      setIsSearchHistoryOpen(true)
    }
  }

  const ListBoxWrapper = isAutocompleteOpen ? Fragment : VisuallyHidden

  const [isSearchHistoryOpen, setIsSearchHistoryOpen] = useState(false)

  const inputRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement
      const isInsideClick =
        inputRef.current?.contains(targetElement) || dropDownRef.current?.contains(targetElement)

      // When opening the context menu, the click event is triggered on the document element
      // Probably because the click event does not have capture: true
      const isContextMenuTriggerClick = targetElement.isSameNode(document.documentElement)
      const isContextMenuClick = Boolean(targetElement.closest('.saved-search-dropdown-menu'))
      const isAlertDialogClick =
        Boolean(targetElement.closest('[role="dialog"]')) || Boolean(targetElement.dataset['state'])

      const isAllowedClick = isContextMenuTriggerClick || isContextMenuClick || isAlertDialogClick

      if (isInsideClick || isAllowedClick) {
        return
      }

      setIsSearchHistoryOpen(false)
    }

    document.addEventListener('click', handleClickOutside, {
      capture: true,
    })
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  if (isAutocompleteOpen && isSearchHistoryOpen) {
    setIsSearchHistoryOpen(false)
  }

  return (
    <AutocompleteWrapper ref={inputRef}>
      <VisuallyHidden>
        <Label> {t('area_search:label')}</Label>
      </VisuallyHidden>
      <AutocompleteBox {...getComboboxProps()}>
        {!hasSelectedOptions && <Spacer size="4x" axis="x" />}
        <InputWrapper direction="row">
          <AutocompleteInput
            onInputFocus={handleInputFocus}
            {...{
              getInputProps,
              placeholder,
              optionToLabel,
              selectedOptions,
              maxOptions,
              getSelectedItemProps,
              getDropdownProps,
              isComboboxOpen: isAutocompleteOpen,
              removeSelectedItem,
              hasLargePills: true,
            }}
          />
        </InputWrapper>
        <ButtonElementWrapper>
          <StyledButton variant="primary" type="submit">
            {t('search')}
          </StyledButton>
        </ButtonElementWrapper>
      </AutocompleteBox>
      <ListBoxWrapper>
        <AutocompleteListbox
          {...{
            inputValue,
            isLoading,
            isOpen: isAutocompleteOpen,
            optionToLabel,
            options,
            optionToSublabel,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            horizontalOffset: 16,
          }}
        />
      </ListBoxWrapper>

      {isSearchHistoryOpen && (
        <SearchHistoryDropdown
          ref={dropDownRef}
          onListItemPress={() => setIsSearchHistoryOpen(false)}
          horizontalOffset={16}
        />
      )}
    </AutocompleteWrapper>
  )
}
