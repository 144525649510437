import styled from '@emotion/styled'
import type { PropsWithChildren } from 'react'

const INDENT_SVG_WIDTH_ATTR = 6

const CardRoot = styled.div({ display: 'flex', flexDirection: 'column' })
const CardSection = styled.div(({ theme }) => ({
  background: 'white',
  '&:first-of-type': {
    borderTopLeftRadius: theme.radii.lg,
    borderTopRightRadius: theme.radii.lg,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.radii.lg,
    borderBottomRightRadius: theme.radii.lg,
  },
}))

const CardHeader = styled(CardSection)(({ theme }) => ({
  padding: theme.spacing['4x'],
  paddingBottom: 0,
}))

const Title = styled.h3(({ theme }) => ({
  ...theme.typography.title.lg,
  padding: theme.spacing['4x'],
}))

function CardTitle({ children }: PropsWithChildren) {
  return <Title>{children}</Title>
}

const CardBody = styled(CardSection)(({ theme }) => ({
  padding: theme.spacing['8x'],
  paddingTop: theme.spacing['4x'],

  flex: 1,
}))

const DividerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  background: 'white',
  marginInline: INDENT_SVG_WIDTH_ATTR,
  '> svg': {
    marginInline: -INDENT_SVG_WIDTH_ATTR,
  },
  '> svg:nth-child(2)': {
    transform: 'rotate(180deg)',
  },
})

function CardDivider() {
  return (
    <DividerContainer>
      <svg
        width={INDENT_SVG_WIDTH_ATTR}
        height="26"
        viewBox="0 0 6 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 0V13C6 6.5 0.0100002 6.5 0.0100002 0H6Z" fill="white" />
        <path d="M5.99977 26H0.00976562C0.00976562 19.5 6.00977 19.5 6.00977 13V26H5.99977Z" fill="white" />
      </svg>
      <svg
        width={INDENT_SVG_WIDTH_ATTR}
        height="26"
        viewBox="0 0 6 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 0V13C6 6.5 0.0100002 6.5 0.0100002 0H6Z" fill="white" />
        <path d="M5.99977 26H0.00976562C0.00976562 19.5 6.00977 19.5 6.00977 13V26H5.99977Z" fill="white" />
      </svg>
    </DividerContainer>
  )
}

export const HuggableCard = Object.assign(CardRoot, {
  Header: CardHeader,
  Title: CardTitle,
  Body: CardBody,
  Divider: CardDivider,
})
