import styled from '@emotion/styled'
import { Paragraph, Stack, theme } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import apartmentsImg640 from '../../../assets/images/landing/apartments/640x512.webp'
import apartmentsImg768 from '../../../assets/images/landing/apartments/768x614.webp'
import apartmentsImg1024 from '../../../assets/images/landing/apartments/1024x820.webp'
import apartmentsImg1280 from '../../../assets/images/landing/apartments/1280x1024.webp'
import apartmentsImg1920 from '../../../assets/images/landing/apartments/1920x1536.webp'
import apartmentsImg2560 from '../../../assets/images/landing/apartments/2560x2048.webp'
import glassHouseImg640 from '../../../assets/images/landing/glass-house/640x512.webp'
import glassHouseImg768 from '../../../assets/images/landing/glass-house/768x614.webp'
import glassHouseImg1024 from '../../../assets/images/landing/glass-house/1024x820.webp'
import glassHouseImg1280 from '../../../assets/images/landing/glass-house/1280x1024.webp'
import glassHouseImg1920 from '../../../assets/images/landing/glass-house/1920x1536.webp'
import glassHouseImg2560 from '../../../assets/images/landing/glass-house/2560x2048.webp'
import pinkStairsImg640 from '../../../assets/images/landing/pink-stairs/640x512.webp'
import pinkStairsImg768 from '../../../assets/images/landing/pink-stairs/768x614.webp'
import pinkStairsImg1024 from '../../../assets/images/landing/pink-stairs/1024x820.webp'
import pinkStairsImg1280 from '../../../assets/images/landing/pink-stairs/1280x1024.webp'
import pinkStairsImg1920 from '../../../assets/images/landing/pink-stairs/1920x1536.webp'
import pinkStairsImg2560 from '../../../assets/images/landing/pink-stairs/2560x2048.webp'

import { HuggableCard } from './huggable-card'
import { ResponsiveImage } from './responsive-image'

const IMAGES = [
  {
    '640': apartmentsImg640,
    '768': apartmentsImg768,
    '1024': apartmentsImg1024,
    '1280': apartmentsImg1280,
    '1920': apartmentsImg1920,
    '2560': apartmentsImg2560,
  },
  {
    '640': glassHouseImg640,
    '768': glassHouseImg768,
    '1024': glassHouseImg1024,
    '1280': glassHouseImg1280,
    '1920': glassHouseImg1920,
    '2560': glassHouseImg2560,
  },
  {
    '640': pinkStairsImg640,
    '768': pinkStairsImg768,
    '1024': pinkStairsImg1024,
    '1280': pinkStairsImg1280,
    '1920': pinkStairsImg1920,
    '2560': pinkStairsImg2560,
  },
]

const Wrapper = styled.section(({ theme }) => ({
  backgroundColor: theme.colors.core.softPink,
  padding: `${theme.spacing['12x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Title = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
}))

const CardsContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing['6x'],
  [theme.mediaQueries.xlUp]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}))

const Image = styled(ResponsiveImage)(({ theme }) => ({
  aspectRatio: '5/4',
  objectFit: 'cover',
  width: '100%',
  borderRadius: theme.radii.sm,
}))

export function Articles() {
  const { t } = useTranslation('articles')
  const items: string[] = t('items', { returnObjects: true })
  return (
    <Wrapper>
      <Stack
        gap="8x"
        style={{
          // TODO: Create shared component for this
          maxWidth: 1200,
          margin: '0 auto',
        }}
      >
        <Title>{t('title')}</Title>
        <CardsContainer>
          {items.map((text, index) => {
            const srcSet = IMAGES[index]
            return (
              <HuggableCard key={index}>
                <HuggableCard.Header>
                  <Image
                    src={srcSet[640]}
                    alt=""
                    // Set low priority since these images are not background images
                    // and therefore not critical to the initial render.
                    fetchPriority="low"
                    srcSet={srcSet}
                    sizes={`100vw, (min-width: ${theme.breakpoints.xl}px) 420px`}
                  />
                </HuggableCard.Header>
                <HuggableCard.Divider />
                <HuggableCard.Body>
                  <Paragraph size="xl">{text}</Paragraph>
                </HuggableCard.Body>
              </HuggableCard>
            )
          })}
        </CardsContainer>
      </Stack>
    </Wrapper>
  )
}
