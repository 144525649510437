import { currentBrand } from '../../../config'

import LandingPageContentFi from './landing-page-content.fi'
import LandingPageContentSv from './landing-page-content.se'
import LandingPageContentFr from './landing-page-content.fr'
import LandingPageContentFallback from './landing-page-content'

export let LandingPageContent = LandingPageContentFallback

if (currentBrand === 'qasa_finland') {
  LandingPageContent = LandingPageContentFi
} else if (currentBrand === 'qasa_france') {
  LandingPageContent = LandingPageContentFr
} else if (currentBrand === 'qasa') {
  LandingPageContent = LandingPageContentSv
}
