import styled from '@emotion/styled'
import { Stack, useBreakpointValue } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { currentBrand } from '../../../config'

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.brandSecondary,
  padding: `${theme.spacing['24x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const StatsLabel = styled.span(({ theme }) => ({
  ...theme.typography.display.md,
  // TODO: Use brand "pink" when it's available in QDS
  color: theme.colors.core.uiPinkDark,
  textAlign: 'center',
}))
const StatsValue = styled.span(({ theme }) => ({
  ...theme.typography.body.xl,
  // TODO: Use brand "pink" when it's available in QDS
  color: theme.colors.core.uiPinkDark,
  textAlign: 'center',
}))

export function Stats() {
  const { t } = useTranslation('stats')
  const isLgOrAbove = useBreakpointValue({ base: false, lg: true }, { ssr: true })

  const stats: { heading: string; description: string }[] = t('items', {
    returnObjects: true,
    context: currentBrand,
  })

  return (
    <Wrapper>
      <Stack
        direction={isLgOrAbove ? 'row' : 'column'}
        justifyContent={'space-between'}
        alignItems="center"
        gap={isLgOrAbove ? '8x' : '24x'}
      >
        {stats.map(({ heading, description }) => (
          <Stack as="p" key={heading} gap={isLgOrAbove ? '8x' : '4x'}>
            <StatsLabel>{heading}</StatsLabel>
            <StatsValue>{description}</StatsValue>
          </Stack>
        ))}
      </Stack>
    </Wrapper>
  )
}
