import { useState } from 'react'
import styled from '@emotion/styled'
import { CheckCircleIcon, Paragraph, Stack } from '@qasa/qds-ui'

import { RotatingArrow } from '../../ui-shared/_core/rotating-arrow'

const Description = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.inset,
  padding: theme.spacing['4x'],
  paddingLeft: theme.spacing['6x'],
  paddingBottom: theme.spacing['6x'],
  borderRadius: theme.radii.sm,
}))
const UspHeaderButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  textAlign: 'left',
})
const ReadMore = styled.div({ display: 'flex', alignItems: 'center', marginLeft: 'auto' })
type RoleSpecificUspsProps = {
  title: string
  description: string
}

export function ExpandableUsp({ title, description }: RoleSpecificUspsProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Stack gap="2x">
      <UspHeaderButton onClick={() => setIsOpen(!isOpen)}>
        <CheckCircleIcon color="positive" size={16} style={{ marginRight: 8 }} />
        <Paragraph>{title}</Paragraph>
        <ReadMore>
          <RotatingArrow {...{ isOpen }} />
        </ReadMore>
      </UspHeaderButton>
      {isOpen && <Description>{description}</Description>}
    </Stack>
  )
}
