import styled from '@emotion/styled'
import { ArrowRightIcon, Label, Stack } from '@qasa/qds-ui'

const IconContainer = styled.div({
  flexShrink: 0,
})

type Props = {
  usps: string[]
  // TODO: Supporting `column` in this component doesn't make sense
  // since it's called `InlineUsps`.
  direction: 'row' | 'column'
}
export function InlineUsps({ usps, direction }: Props) {
  return (
    <Stack direction={direction} gap="3x" wrap="wrap">
      {usps.map((usp) => (
        <Stack key={usp} gap="2x" direction="row" alignItems="center">
          <IconContainer>
            <ArrowRightIcon size={16} style={{ flexShrink: 0 }} />
          </IconContainer>
          <Label size="sm" as="p">
            {usp}
          </Label>
        </Stack>
      ))}
    </Stack>
  )
}
