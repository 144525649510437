import type { BrandType } from '../../../../config/index.types'
import type { LanguageCode } from '../../../../helpers/i18n'

export type QuestionItemProps = { nameI18nKey: string; href?: string }

const sv = [
  { nameI18nKey: 'sv.q1', href: 'https://support.qasa.se/hc/sv/articles/115009721208' },
  { nameI18nKey: 'sv.q2', href: 'https://support.qasa.se/hc/sv/articles/115012592167' },
  { nameI18nKey: 'sv.q3', href: 'https://support.qasa.se/hc/sv/articles/6168948955409' },
  { nameI18nKey: 'sv.q4', href: 'https://support.qasa.se/hc/sv/articles/115009719628' },
  { nameI18nKey: 'sv.q5', href: 'https://support.qasa.se/hc/sv/articles/12668192470289' },
  { nameI18nKey: 'sv.q6', href: 'https://support.qasa.se/hc/sv/articles/115010448107' },
  { nameI18nKey: 'sv.q7', href: 'https://support.qasa.se/hc/sv/articles/115010611768' },
  { nameI18nKey: 'sv.q8', href: 'https://support.qasa.se/hc/sv/articles/115010455867' },
]

export const QUESTIONS: Record<BrandType, Partial<Record<LanguageCode, QuestionItemProps[]>>> = {
  blocket: {},
  qasa: {
    sv,
    en: [
      { nameI18nKey: 'sv.q1', href: 'https://support.qasa.se/hc/en-us/articles/115009721208' },
      { nameI18nKey: 'sv.q2', href: 'https://support.qasa.se/hc/en-us/articles/115012592167' },
      { nameI18nKey: 'sv.q3', href: 'https://support.qasa.se/hc/en-us/articles/6168948955409' },
      { nameI18nKey: 'sv.q4', href: 'https://support.qasa.se/hc/en-us/articles/115009719628' },
      { nameI18nKey: 'sv.q5', href: 'https://support.qasa.se/hc/en-us/articles/12668192470289' },
      { nameI18nKey: 'sv.q6', href: 'https://support.qasa.se/hc/en-us/articles/115010448107' },
      { nameI18nKey: 'sv.q7', href: 'https://support.qasa.se/hc/en-us/articles/115010611768' },
      { nameI18nKey: 'sv.q8', href: 'https://support.qasa.se/hc/en-us/articles/115010455867' },
    ],
  },
  qasa_finland: {
    fi: [
      { nameI18nKey: 'fi.q1', href: 'https://support.qasa.fi/hc/fi/articles/6736063739025' },
      { nameI18nKey: 'fi.q2', href: 'https://support.qasa.fi/hc/fi/articles/360042797611' },
      { nameI18nKey: 'fi.q3', href: 'https://support.qasa.fi/hc/fi/articles/360046581211' },
      { nameI18nKey: 'fi.q4', href: 'https://support.qasa.fi/hc/fi/articles/360043645231' },
      { nameI18nKey: 'fi.q5', href: 'https://support.qasa.fi/hc/fi/articles/360052964512' },
      { nameI18nKey: 'fi.q6', href: 'https://support.qasa.fi/hc/fi/articles/6803884340753' },
      { nameI18nKey: 'fi.q7', href: 'https://support.qasa.fi/hc/fi/articles/6528408175377' },
      { nameI18nKey: 'fi.q8', href: 'https://support.qasa.fi/hc/fi/articles/360047299631' },
    ],
  },
  dotcom: {},
  qasa_france: {
    en: [
      { nameI18nKey: 'fr.q1', href: 'https://support.qasa.fr/hc/en-us/articles/16324784946705' },
      { nameI18nKey: 'fr.q2', href: 'https://support.qasa.fr/hc/en-us/articles/16676678252049' },
      { nameI18nKey: 'fr.q3', href: 'https://support.qasa.fr/hc/en-us/articles/16780149954449' },
      { nameI18nKey: 'fr.q4', href: 'https://support.qasa.fr/hc/en-us/articles/16676782233617' },
      { nameI18nKey: 'fr.q5', href: 'https://support.qasa.fr/hc/en-us/articles/16522541673617' },
      { nameI18nKey: 'fr.q6', href: 'https://support.qasa.fr/hc/en/articles/16680069333393' },
      { nameI18nKey: 'fr.q7', href: 'https://support.qasa.fr/hc/en-us/articles/16677025547921' },
      {
        nameI18nKey: 'fr.q8',
        href: 'https://support.qasa.fr/hc/en-us/articles/22318011198993-Pourquoi-opter-pour-la-reprise-de-gestion',
      },
    ],
    fr: [
      { nameI18nKey: 'fr.q1', href: 'https://support.qasa.fr/hc/fr/articles/16324784946705' },
      { nameI18nKey: 'fr.q2', href: 'https://support.qasa.fr/hc/fr/articles/16676678252049' },
      { nameI18nKey: 'fr.q3', href: 'https://support.qasa.fr/hc/fr/articles/16780149954449' },
      { nameI18nKey: 'fr.q4', href: 'https://support.qasa.fr/hc/fr/articles/16676782233617' },
      { nameI18nKey: 'fr.q5', href: 'https://support.qasa.fr/hc/fr/articles/16522541673617' },
      { nameI18nKey: 'fr.q6', href: 'https://support.qasa.fr/hc/fr/articles/16680069333393' },
      { nameI18nKey: 'fr.q7', href: 'https://support.qasa.fr/hc/fr/articles/16677025547921' },
      {
        nameI18nKey: 'fr.q8',
        href: 'https://support.qasa.fr/hc/fr/articles/22318011198993-Pourquoi-opter-pour-la-reprise-de-gestion',
      },
    ],
  },
}
