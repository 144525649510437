import helsinki from './helsinki.jpg'
import jyvaskyla from './jyvaskyla.jpg'
import kuopio from './kuopio.jpg'
import lahti from './lahti.jpg'
import oulu from './oulu.jpg'
import pori from './pori.jpg'
import tampere from './tampere.jpg'
import turku from './turku.jpg'

export { helsinki, jyvaskyla, kuopio, lahti, oulu, pori, tampere, turku }
