import styled from '@emotion/styled'
import { Button, Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { EXTERNAL_LINKS } from '../../../config/external-links'
import img640 from '../../../assets/images/landing/product-showcase/640x400.webp'
import img768 from '../../../assets/images/landing/product-showcase/768x480.webp'
import img1024 from '../../../assets/images/landing/product-showcase/1024x640.webp'
import img1280 from '../../../assets/images/landing/product-showcase/1280x800.webp'
import img1920 from '../../../assets/images/landing/product-showcase/1920x1200.webp'
import img2560 from '../../../assets/images/landing/product-showcase/2560x1600.webp'

import { ResponsiveImage } from './responsive-image'

const BREAKPOINT = 'lgUp'

const Wrapper = styled.div(({ theme }) => ({
  position: 'relative',
  background: theme.colors.core.brown,
  [theme.mediaQueries[BREAKPOINT]]: {
    height: 'clamp(480px, 80vh, 800px)',
  },
}))

const Img = styled(ResponsiveImage)(({ theme }) => ({
  width: '100%',
  minHeight: 256,
  '@supports (aspect-ratio: 1 / 1)': {
    aspectRatio: '1 / 1',
  },
  objectFit: 'cover',
  [theme.mediaQueries[BREAKPOINT]]: {
    position: 'static',
    height: '100%',
  },
}))

const ContentContainer = styled.div(({ theme }) => ({
  paddingTop: theme.spacing['12x'],
  paddingBottom: theme.spacing['12x'],
  paddingLeft: theme.spacing['6x'],
  paddingRight: theme.spacing['6x'],
  [theme.mediaQueries[BREAKPOINT]]: {
    paddingLeft: theme.spacing['12x'],
    paddingRight: theme.spacing['12x'],
    position: 'absolute',
    inset: 0,
  },
}))

const Title = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
  color: 'white',
  whiteSpace: 'pre-line',
  [theme.mediaQueries[BREAKPOINT]]: {
    ...theme.typography.display.md,
  },
}))

const Description = styled.p(({ theme }) => ({
  ...theme.typography.body.xl,
  color: 'white',
  maxWidth: '40ch',
}))

export function ProductShowcase() {
  const { t } = useTranslation('product_showcase')
  const productShowCaseUrl = EXTERNAL_LINKS['leaseTakeoverForm']
  return (
    <Wrapper>
      <Img
        src={img640}
        alt=""
        srcSet={{
          '640': img640,
          '768': img768,
          '1024': img1024,
          '1280': img1280,
          '1920': img1920,
          '2560': img2560,
        }}
      />
      <ContentContainer>
        <Title>{t('title')}</Title>
        <Spacer size="8x" />
        <Description>{t('description')}</Description>
        <Spacer size="12x" />
        {productShowCaseUrl && (
          <Button variant="tertiary" as="a" href={productShowCaseUrl} target="_blank">
            {t('cta')}
          </Button>
        )}
      </ContentContainer>
    </Wrapper>
  )
}
