import styled from '@emotion/styled'
import { Heading } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { getPath } from '../../../routing/get-path'
import img from '../../../assets/images/landing_page_pop_content.jpg'
import { currentBrand } from '../../../config'
import { LinkButtonV2 } from '../../../ui-shared/_core/link-button-v2'

import { BackgroundImage } from './background-image'

const HeadingSection = styled(Heading)(({ theme }) => ({
  whiteSpace: 'pre-line',
  flexGrow: 1,
  color: theme.colors.core.white,
  ...theme.typography.display.sm,
  [theme.mediaQueries.smUp]: {
    ...theme.typography.display.md,
  },
  [theme.mediaQueries.lgUp]: {
    ...theme.typography.display.xl,
  },
  [theme.mediaQueries['2xlUp']]: {
    ...theme.typography.display['2xl'],
  },
}))

export function PopContent() {
  const { t } = useTranslation('pop_content')
  const translationOptions = { context: currentBrand }

  return (
    <BackgroundImage img={img.src} label={t('label', translationOptions)}>
      <HeadingSection>{t('heading', translationOptions)}</HeadingSection>
      <LinkButtonV2 size={'xl'} variant={'tertiary'} href={getPath('findHome')}>
        {t('cta', translationOptions)}
      </LinkButtonV2>
    </BackgroundImage>
  )
}
