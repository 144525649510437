import { graphql } from '@qasa/graphql'

export const CITY_HOME_COUNT = graphql(`
  query CityHomeCount($searchParams: HomeSearchParamsInput!) {
    homeSearch: homeIndexSearch(params: $searchParams) {
      documents {
        totalCount
      }
    }
  }
`)
